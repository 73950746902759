import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from 'react-bootstrap'

const ContactPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Contact Us" />
    <h1 style={styles.header}>Contact Us</h1>
    <div style={styles.body}>
    <Container fluid={true}>
      <h5 style={{...styles.italic, ...styles.primaryText}}>Ready to Set Sail Toward a Brighter Financial Future?</h5>
      <p>
        We’d like to help. Thank you for considering the Coastal Advisory Group for your wealth management needs.
      </p>
      <Row style={{paddingBottom: '30px'}}>
        <Col lg={3}>
          <h6>Toll Free</h6>
          <a href="tel:8004784452">800-478-4452</a>
        </Col>
        <Col lg={3}>
          <h6>Office</h6>
          <a href="tel:+15136191100">513-619-1100</a>
        </Col>
        <Col lg={4}>
          <h6>Email</h6>
          <a href="mailto:mmueller@coastaleq.com">mmueller@coastaleq.com</a>
        </Col>
      </Row>
      <h6 style={{textDecoration: 'underline'}}>Office Hours</h6>
      <p style={{paddingBottom: '30px'}}>
        9am to 5pm Monday through Friday
        Except on days the Market is closed.
      </p>
      <h6 style={{textDecoration: 'underline'}}>By Appointment Office Locations</h6>
        <div>
          <span style={{fontWeight: '700'}}>
            Michael Mueller, President<br/>
            Coastal Advisory Group<br/>
          </span>
          312 Walnut Street, Suite 1600<br/>
          Cincinnati, OH 45202<br/>
        </div>
        <div style={{paddingTop: '10px'}}>
          <span style={{fontWeight: '700'}}>
            Hyde Park<br/>
            Rookwood Tower<br/>
          </span>
          3805 Edwards Road, Suite 550<br/>
          Cincinnati, OH 45209 
        </div>
        <div style={{paddingTop: '10px'}}>
          <span style={{fontWeight: '700'}}>
            Blue Ash<br/>
            Westlake Center<br/>
          </span>
          4555 Lake Forest Dr., Suite 650<br/>
          Cincinnati, OH 45242 
        </div>
        <div style={{paddingTop: '10px'}}>
          <span style={{fontWeight: '700'}}>
            Northern Kentucky<br/>
            Turfway Ridge Office Park<br/>
          </span>
          7310 Turfway Road, Suite 550<br/>
          Florence, KY 41042 
        </div>
        <div style={{paddingTop: '10px'}}>
          <span style={{fontWeight: '700'}}>
            West Chester<br/>
            Union Centre Office Park II<br/>
          </span>
          9078 Union Centre Blvd, Suite 350 <br/>
          West Chester, OH 45069
        </div>
        <div style={{paddingTop: '10px'}}>
          <span style={{fontWeight: '700'}}>
            Kenwood<br/>
            Kenwood Professional Building<br/>
          </span>
          9403 Kenwood Road Suite A-106 <br/>
          Blue Ash, OH 45242
        </div>

      <iframe className="gmap" src="https://www.google.com/maps/d/u/0/embed?mid=1bxQhzRBMs7dyIzt0TTqECYVLgbQmEJye" frameBorder="0" allowFullScreen></iframe>
      <div className="beach-couple-background">
        <div className="img--inner-content">
          <h5 style={{color: '#fff'}}>Click To Get Started On Your Journey</h5>
          <a href="tel:+15136191100">
            <Button className="main-btn">CLICK TO CALL</Button>
          </a>
        </div>
      </div>
    </Container>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default ContactPage
